import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;
export default class ExamCourseService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/exams`;

    }

    all(examId) {
        let url = `${this.#api}/${examId}/course`;
        return apiService.get(url);
    }


    paginate(examId, type = null) {
        let url = `${this.#api}/${examId}/course`;
        if (type)
            url = url + '?type=' + type;
        return apiService.get(url);
    }

    update(examId, id, data) {
        let url = `${this.#api}/${examId}/course/${id}`
        return apiService.put(url, data);

    }

    store(examId, data) {
        let url = `${this.#api}/${examId}/course`
        return apiService.post(url, data);

    }

    show(examId, id) {
        let url = `${this.#api}/${examId}/course/${id}`
        return apiService.get(url);
    }

    delete(examId, id) {
        let url = `${this.#api}/${examId}/course/${id}`
        return apiService.delete(url);
    }

    recalculateCourseResult(examId, courseId) {
        let url = `${this.#api}/${examId}/course/${courseId}/re-calculate/course-result`
        return apiService.get(url);
    }
}
