<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">

              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>
                  Result entry for {{ course && course.course && course.course.title ? course.course.title : '-' }}</h4>


              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3"
                   v-if="result_students && result_students.length>0">
                <router-link
                    :to="{name:'results-view-courses-assessments',params:{slug:exam.slug, 'courseId':course.course.id}}"
                   >
                  Additional assessment marking
                </router-link>
                <v-btn :loading="isSaveButtonLoading" @click="saveStudentResultByCourse()"
                       class="btn btn-primary ml-2">
                  Save
                </v-btn>
              </div>
            </div>

            <div class="row mb-5" style="background-color:whitesmoke">
              <div class="col-md-4">
                <b>Course: </b> {{ course && course.course && course.course.title ? course.course.title : null }} <br>
                <b>Total student: </b> {{result_students.length}}
              </div>

              <div class="col-md-4">
                <b>Exam title: </b> {{ exam ? exam.title : null }} <br>
                <b>Exam type: </b> {{ exam && exam.type ? exam.type.toUpperCase() : null }} <br>

              </div>

              <div class="col-md-4">
<!--                <b>Theory full mark: </b> {{ course &&  course.theory_mark ? course.theory_mark : null }} <br>-->
<!--                <b>Theory pass mark: </b> {{ course &&  course.theory_pass_mark ? course.theory_pass_mark : null }}   <br>-->
<!--                <b>Practical full mark: </b> {{ course &&  course.practical_mark ? course.practical_mark : null }} <br>-->
<!--                <b>Practical pass mark: </b> {{ course &&  course.practical_pass_mark ? course.practical_pass_mark : null }}   <br>-->
                <b>Program: </b> {{ (exam && exam.program) ? exam.program.title : null }} <br>
                <b>Semester/year: </b> {{ (exam && exam.grade) ? exam.grade.title : null }} <br>
              </div>
              <div class="col-10">
                <v-select v-model="class_room" :items="class_rooms" outlined dense label="Select classroom" item-text="title"
                          item-value="id"></v-select>
              </div>
              <div class="col-2">
                <v-btn depressed class="btn btn-primary text-white" @click="getAssignedStudents">Filter</v-btn>
                <!--                <v-btn depressed class="btn" @click=" resetStudentList">Reset</v-btn>-->
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="" v-if="result_students && result_students.length>0">
                  <table class="table">
                    <thead>
                    <th>Student</th>
                    <th>Attendance</th>
                    <th style="width: 120px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">Theory ({{ course &&  course.theory_pass_mark ? course.theory_pass_mark : null }}/{{ course &&  course.theory_mark ? course.theory_mark : null }})</th>
                    <th style="width: 120px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">Practical ({{ course &&  course.practical_pass_mark ? course.practical_pass_mark : null }}/{{ course &&  course.practical_mark ? course.practical_mark : null }})</th>
                    <th class="text-center">GPA</th>
                    <th class="text-center">Grade</th>
                    <th class="text-center">Remarks</th>
                    <th class="text-center"></th>
                    </thead>
                    <tbody v-cloak>
                    <tr v-for="(student, index) of result_students" :key="index">
                      <td>
                        <a href="#!" @click.prevent="openSummaryPage(student)" class="mb-1">
                          <b>  {{ student.name }} </b> |
                        </a>
                        <a href="#!" @click.prevent="openSummaryPage(student)" class="mb-1">
                          <b>  {{student.crn_no  ? student.crn_no : 'NA'}} </b> <br/>
                        </a>


                        <span class="text-secondary">
                           <b> Symbol No.:</b>  {{student.symbol_no  ? student.symbol_no : 'NA'}} |
                            <span class="text-secondary" v-if="student.midas_id">
                         <b> External ID:</b>  {{student.midas_id!=null  ? student.midas_id : 'NA'}}
                        </span>

                        </span>
                      </td>
                      <td style="width: 90px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">
                        <v-switch @change="changeStudentPresent"
                            v-model="student.is_present"
                            :label="student.is_present? 'Present' : 'Absent'">
                        </v-switch>
                      </td>
                      <td style="width: 90px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">
                        <v-text-field outlined dense :tabindex="index+1"
                                      type="number"
                                      :readonly="student.has_re_exam_results || !student.is_present"
                                      v-model="student.theory_obtained_marks" min="0" style="max-width: 100px"
                                      label="Mark "
                                      placeholder="Mark"></v-text-field>
                      </td>

                      <td style="width: 90px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">
                        <v-text-field outlined dense min="0"
                                      type="number"
                                      :readonly="student.has_re_exam_results  || !student.is_present"
                                      v-model="student.practical_obtained_marks" style="max-width: 100px"
                                      label="Mark "
                                      placeholder="Mark"></v-text-field>
                      </td>


                      <td class="text-center">
                       <simplebold>{{student.grade_point ? student.grade_point : 'NA'}}</simplebold>
                      </td>
                      <td class="text-center">
                        <simplebold>{{student.total_grade ? student.total_grade : 'NA'}}</simplebold>
                      </td>
                      <td class="text-center">
<!--                              <span class="font-weight-bold">TH:</span><span class="font-weight-medium">{{ student.theory_grade ? student.theory_grade : "N/A" }}</span><br>-->
<!--                              <span class="font-weight-bold">PR:</span><span class="font-weight-medium">{{ student.practical_grade ? student.practical_grade : "N/A" }}</span>-->
<!--                              <br>-->
                              <span class="badge badge-success" v-if="student.is_pass">Pass</span>
                              <span class="badge badge-danger" v-else>Fail</span>
                      </td>
                      <td>
                        <b-dropdown v-if="student.is_saved"
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-ver"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text
                                tag="div"
                                class="navi-item"
                            >
                              <a
                                  class="ml-2 navi-link"
                                  @click="openDialog(student)"
                                  href="javascript:void(0)"
                              >
                              <span class="navi-icon">
                              <i class="fa fa-marker"></i>
                              </span>
                                <span class="navi-text">Re-examination result</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text
                                v-if="student.has_re_exam_results"
                                tag="div"
                                class="navi-item"
                            >
                              <a
                                  class="ml-2 navi-link"
                                  @click="viewPreviousResults(student)"
                                  href="javascript:void(0)"
                              >
                              <span class="navi-icon">
                              <i class="fa fa-eye"></i>
                              </span>
                                <span class="navi-text">View previous results</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <v-alert v-else
                         type="error"

                         prominent
                         elevation="2"
                         colored-border
                         border="left"
                >
                 No students are assigned to this course
                </v-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            {{re_exam_modal_title}}
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="re_exam.theory_mark"
                  label="Theory mark"
                  outlined
                  :error="$v.re_exam.theory_mark.$error"
                  dense
              ></v-text-field>
              <span class="text-danger" v-if="$v.re_exam.theory_mark.$error"
                    >This information is required</span>
            </v-col>

            <v-col cols="6">
              <v-text-field
                  label="Practical mark"
                  outlined
                  dense
                  :error="$v.re_exam.practical_mark.$error"
                  v-model="re_exam.practical_mark"
              ></v-text-field>
              <span class="text-danger" v-if="$v.re_exam.practical_mark.$error"
              >This information is required</span>
            </v-col>



            <v-col cols="12" class="text-right">

              <v-btn
                  class="cancel-btn"
                  depressed
                  @click="closeDialog"
              >Cancel
              </v-btn>
              <v-btn :loading="is_loading" depressed outlined @click="saveReExamResult()"
                     class="text-white ml-2 btn btn-primary">
                Save result
              </v-btn>
            </v-col>
          </v-row>


        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="previousResultsDialog" max-width="800">
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            {{previous_results_modal_title}}
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closePreviousResultsDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <table class="table">
                <thead>
                <th class="text-center">Course</th>
                <th class="text-center">Theory</th>
                <th class="text-center">Practical</th>
                <th class="text-center">Attendance</th>
                <th class="text-center">Result archived date</th>
                <th></th>
                </thead>

                <tbody>
                <tr v-for="(result, index) of previous_results" :key="index">
                  <td class="text-center">{{result.exam_course_title}}</td>
                  <td class="text-center">{{result.theory_obtained_marks}}</td>
                  <td class="text-center">{{result.practical_obtained_marks}}</td>
                  <td class="text-center">{{result.is_absent?'Present':'Absent'}}</td>
                  <td class="text-center">{{result.result_archived_date}}</td>
                  <td>
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-ver"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text
                          tag="div"
                          class="navi-item"
                      >
                        <a
                            class="ml-2 navi-link"
                            @click="deletePreviousResult(result.id)"
                            href="javascript:void(0)"
                        >
                              <span class="navi-icon">
                              <i class="fa fa-trash"></i>
                              </span>
                          <span class="navi-text">Delete </span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                  </td>
                </tr>
                </tbody>
              </table>
            </v-col>

            <v-col cols="12" class="text-right">

              <v-btn
                  class="cancel-btn"
                  depressed
                  @click="closePreviousResultsDialog"
              >Close
              </v-btn>

            </v-col>
          </v-row>


        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ExamService from "@/core/services/exam/ExamService";
import ExamCourseService from "@/core/services/exam/course/ExamCourseService"
import AddManageResult from "@/view/pages/results/courses/AddManageResult";
import CoursesService from "@/core/services/courses/CoursesService";
import StudentResultService from "@/core/services/user/student/result/StudentResultService";
import StudentCourseResultService from "@/core/services/user/student/result/StudentCourseResultService";

const examService = new ExamService();
const examCourseService = new ExamCourseService();
const academicClassService = new AcademicClassService();
const courseService = new CoursesService();
const resultService = new StudentResultService();
const courseResultService = new StudentCourseResultService();

export default {
  name: "CourseExamResult",
  validations:{
    re_exam:{
      theory_mark:{required},
      practical_mark:{},
    }

  },
  components: {
    AddManageResult
  },
  data() {
    return {
      dialog:false,
      previousResultsDialog:false,
      class_room: null,
      class_rooms: [],
      is_loading: false,
      isSaveButtonLoading: false,
      result_students: [],
      exam: {},
      course: {},
      selectedStudents: [],
      academic_classes: [],
      exams: [],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      previous_results:[],
      re_exam_modal_title:'Add reexamination result for',
      previous_results_modal_title:'Previous results for',
      re_exam:{
        student_id:'',
        exam_id:'',
        exam_course_id:'',
        theory_mark:'',
        practical_mark:'0',
      }
    };
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
    slug() {
      return this.$route.params.slug;
    }

  },
  mounted() {
    // this.getAcademicClasses();
    this.getExam();
    //this.getCourse();
  },
  methods: {
    back() {
      this.$tabs.close().then(response => {
        this.$router.push({name: 'exams-summary', params: {exam_id: this.exam.id}});
      });
    }, getClassRooms() {
      academicClassService.getByProgramLevel(this.exam.academic_year_id, this.exam.program_id, this.exam.grade_id).then(response => {
        this.class_rooms = response.data
      })
    },
    getExam() {
      this.$bus.emit('toggleLoader');
      examService.getBySlug(this.slug).then(response => {
        this.exam = response.data.exams;
        this.getExamCourse();
        this.getAssignedStudents();
        this.getClassRooms();
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
        this.$bus.emit('toggleLoader');
      });
    },
    getCourse() {
      courseService.show(this.courseId).then(response => {
        this.course = response.data.courses;
      });
    },

    getExamCourse() {
      examCourseService.show(this.exam.id,this.courseId).then(response => {
        this.course = response.data.course;
      });
    },

    getResultByCourse() {
      courseResultService.getAllByCourseAndExamId(this.exam.id, this.courseId).then(response => {
        this.course_result = response.data;
        if (this.course_result.length > 0) {
          for (let res in this.course_result) {
            this.result_students[res].theory_obtained_marks = this.course_result[res].theory_obtained_marks
            this.result_students[res].practical_obtained_marks = this.course_result[res].practical_obtained_marks
            this.result_students[res].result_status = this.course_result[res].result_status
            this.result_students[res].total_grade = this.course_result[res].total_grade
            this.result_students[res].is_absent = this.course_result[res].is_absent
          }
        }
      })
    }, resetStudentList() {
      this.class_room = null
      this.getAssignedStudents();
    },
    getAssignedStudents() {
      this.$bus.emit('toggleLoader');
      let data = {}
      if (this.class_room)
        data = {academic_class_id: this.class_room}
      examService.getAssignedStudentsByCourse(this.exam.id, this.courseId, data).then(response => {
        this.selectedStudents = response.data;
        this.result_students = this.selectedStudents.map((student) => {
          return {
            student_id: student.student_id,
            crn_no: student.crn_no,
            name: student.name,
            symbol_no: student.symbol_no,
            midas_id: student.midas_id,
            exam_course_id: this.courseId,
            theory_obtained_marks: student.theory_obtained_marks,
            practical_obtained_marks: student.practical_obtained_marks,
            theory_grade: student.theory_grade,
            practical_grade: student.practical_grade,
            exam_id: this.exam.id,
            total_grade: student.total_grade,
            total_gpa: student.total_gpa,
            is_absent: student.is_absent,
            is_present: student.is_present,
            is_pass: student.is_pass,
            has_re_exam_results: student.has_re_exam_results,
            is_saved: student.is_saved,
            full_mark: student.full_mark,
            grade_point: student.grade_point,
            pass_mark: student.pass_mark,
          }
        });
        this.$bus.emit('toggleLoader');
        // this.getResultByCourse();
      });


    },


    openDialog(student) {

      this.re_exam_modal_title = 'Add re-examination result for '+student.name;
      this.re_exam.student_id= student.student_id;
      this.re_exam.exam_id= student.exam_id;
      this.re_exam.exam_course_id = student.exam_course_id;
      this.dialog =true;
    },

    closeDialog() {
      this.dialog =false;
      this.re_exam ={
        student_id:'',
        exam_id:'',
        exam_course_id:'',
        theory_mark:'',
        practical_mark:'',
      };
    },

    openPreviousResultsDialog(student) {

      this.previous_results_modal_title = 'Previous results of '+student.name;
      this.previousResultsDialog =true;
    },

    closePreviousResultsDialog() {
      this.previousResultsDialog =false;
      this.previous_results = [];
    },

    manageResult(exam) {
      this.$refs["manageResult"].showModal(exam);
    },
    deleteAcademicYear(id) {
      this.$confirm({
        message: `Are you sure you want to delete this ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            academicClassService.delete(id).then(response => {
              this.$snotify.success("Record Deleted");
              this.getAcademicClasses();
            });
          }
        }
      });
    }, saveStudentResultByCourse() {
      this.isSaveButtonLoading = true;
      this.$confirm({
        message: `Do you want to save this ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            resultService.saveStudentResultByCourse(this.exam.id, this.result_students).then(response => {
              this.$snotify.success("Record saved");
              this.isSaveButtonLoading = false;
              this.getAssignedStudents();
            }).catch(error => {
              this.isSaveButtonLoading = false;
              // console.log(error)
            }).finally(() => {
            })
          }
        }
      });
    },

    saveReExamResult()
    {
      this.$v.re_exam.$touch();
      if (this.$v.re_exam.$error) {
        setTimeout(() => {
          this.$v.re_exam.$reset();
        }, 3000);
      } else {
        this.is_loading = true;
        resultService.saveStudentReExamResult(this.re_exam).then(response => {
          this.$snotify.success("Re-exam result saved");
          this.is_loading = false;
          this.closeDialog();
          this.getAssignedStudents();

        }).catch(error => {
          this.is_loading = true;
          // console.log(error)
        }).finally(() => {
        })
      }
    },

    viewPreviousResults(student)
    {
      resultService.getPreviousResults(student.exam_id,student.exam_course_id,student.student_id).then(response => {
        this.previous_results = response.data.results;
        this.openPreviousResultsDialog(student);
      }).catch(error => {

      }).finally(() => {
      })
    },

    deletePreviousResult(resultId)
    {
      this.$confirm({
        message: `Are you sure you want to delete ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.is_loading = true;
            resultService.deletePreviousResult(resultId).then(response => {
              this.closePreviousResultsDialog();
              this.$snotify.success("Result deleted");
            }).catch(error => {
              this.$snotify.error("Problem occurred");
            }).finally(() => {
            })
          }
        }
      });
    },
    changeStudentPresent(){
      this.saveStudentResultByCourse();
    },
    openSummaryPage(item){
      this.$router.push({
        name: "students-summary",
        params: { id:item.student_id, type:'student' }
      });
    },
  }
};
</script>

<style>
[v-cloak] > * {
  display: none
}

[v-cloak]::before {
  content: "loading…"
}
</style>
