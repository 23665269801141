import Apiservice from '@/core/services/api.service';
import {API_URL} from '../../../../../common/config'

const apiService = Apiservice;


export default class StudentResultService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    paginate(userId) {
        let url = `${this.#api}/${userId}/student-result`;
        return apiService.get(url);
    }

    getGrades(userId) {
        let url = `${this.#api}/${userId}/student-classes`;
        return apiService.get(url);
    }

    getExamsByGrades(userId, gradeId) {
        let url = `${this.#api}/${userId}/grade/${gradeId}/exams`;
        return apiService.get(url);
    }

    getResultByExams(userId, examId) {
        let url = `${this.#api}/${userId}/exams/${examId}/result`;
        return apiService.get(url);
    }

    update(userId, id, data) {
        let url = `${this.#api}/${userId}/student-result/${id}`
        return apiService.put(url, data);

    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/student-result`
        return apiService.post(url, data);

    }

    show(userId, id) {
        let url = `${this.#api}/${userId}/student-result/${id}`
        return apiService.get(url);
    }

    delete(userId, id) {
        let url = `${this.#api}/${userId}/student-result/${id}`
        return apiService.delete(url);
    }

    saveStudentResultByCourse(examId, data) {
        let url = `${this.#api}/exams/${examId}/student-course-result/save`
        return apiService.post(url, data);
    }

    saveStudentResult(examId, data) {
        let url = `${this.#api}/exams/${examId}/student-result/save`
        return apiService.post(url, data);
    }

    saveStudentReExamResult(data) {
        let url = `${this.#api}/exams/student-re-exam-result/save`
        return apiService.post(url, data);
    }

    getAllByExamId(examId) {
        let url = `${this.#api}/exams/${examId}/results`
        return apiService.get(url);
    }

    importAttendance(examId, data) {
        let url = `${this.#api}/import/exam/attendance/ledger/${examId}`
        return apiService.post(url, data);
    }

    getPreviousResults(examId,courseId,studentId) {
        let url = `${this.#api}/exams/${examId}/get-previous-course/${courseId}/student/${studentId}/results`
        return apiService.get(url);
    }

    deletePreviousResult(resultId) {
        let url = `${this.#api}/exams/${resultId}/delete-previous-result`
        return apiService.delete(url);
    }

}
