import Apiservice from "../../../api.service"
import {API_URL} from "../../../../../common/config";

const apiService = Apiservice;


export default class StudentCourseResultService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    paginate(userId) {
        let url = `${this.#api}/${userId}/student-course-result`;
        return apiService.get(url);
    }

    update(userId, addressId, data) {
        let url = `${this.#api}/${userId}/student-course-result/${addressId}`
        return apiService.put(url, data);

    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/student-course-result`
        return apiService.post(url, data);

    }

    show(userId, addressId) {
        let url = `${this.#api}/${userId}/student-course-result/${addressId}`
        return apiService.get(url);
    }

    delete(userId, addressId) {
        let url = `${this.#api}/${userId}/student-course-result/${addressId}`
        return apiService.delete(url);
    }

    getAllByCourseAndExamId(examId, courseId) {
        let url = `${this.#api}/exam/${examId}/course/${courseId}/result`
        return apiService.get(url);
    }
}
