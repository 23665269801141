<template>
  <v-app>

    <v-dialog
        v-model="dialog"
        width="900"
        title="Manage Result"
    >
      <v-card>
        <v-card-title>
          Add Result
        </v-card-title>
        <v-card-text>
          <div class="row ">
            <div class="col-12">
              <v-form>
                <label>Mark Obtained</label>
                <v-text-field outlined dense>
                </v-text-field>
                <label>Mark Obtained Practical</label>
                <v-text-field outlined dense>
                </v-text-field>
                <label>Mark Remarks</label>
                <v-textarea outlined dense>
                </v-textarea>
              </v-form>
            </div>

          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="syncCourses"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </v-app>
</template>
<script>
import vMultiselectListbox from 'vue-multiselect-listbox'
import {required} from "vuelidate/lib/validators";
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();

export default {
  name: "AddManageResult",
  validations: {
    exam: {
      title: {required},
      type: {required},
      academic_year_id: {required},
      level_id: {required},
      program_id: {required},
      grade_id: {required},
      start_date: {required},
      end_date: {required},
      duration: {required},
      duration_unit: {required},
      exam_location: {required},
    }
  },
  components: {
    vMultiselectListbox
  },
  data() {
    return {
      selectedItem: [],

      exam: {
        title: null,
        type: null,
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: null,
        exam_location: null,
        location_description: null,
        is_active: true
      },
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      menu: false,
      edit: false,
      menu2: false,
      levels: [],
      programs: [],
      academicYears: [],
      courses: [],
      classes: [],
      exam_courses: [],
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class Test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ], locations: [
        {
          text: 'Online',
          value: 'online',
        },
        {
          text: 'In Premise',
          value: 'in-premise',
        },
        {
          text: 'Off Premise',
          value: 'off-premise',
        },
      ],
      durations_units: [
        'minutes', 'hours', 'days', 'months'
      ],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      tab: null,
    };
  },
  mounted() {
    this.getLevels();
    this.getAcademicYears()
    this.getGrades()
  },
  methods: {
    showModal(exam = {}) {
      if (exam.id) {

        this.edit = true
        this.dialog = true
        this.exam = exam
        this.getCourses()
        this.getAssignedCourses()
      }
    },
    hideModal() {
      this.teacher = []
      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = !this.dialog;
    },

    getAssignedCourses() {

      examService.getAssignedCourses(this.exam.id).then(response => {
        this.selectedItem = response.data.courses;


      });
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;

      });
    },
    getCourses() {
      examService.getCourseExam(this.exam.program_id, this.exam.grade_id).then(response => {
        this.courses = response.data.courses;
      });
    },
    getClassExam() {
      examService.getClassExam(this.exam.program_id, this.exam.grade_id).then(response => {
        this.classes = response.data.class;
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    },
    getGrades() {
      gradeLevelService.paginate().then(response => {
        this.gradesLevels = response.data.data;

      });
    },

    syncCourses() {

      this.selectedItem.forEach((item) => {
        this.exam_courses.push({
          exam_id: this.exam.id,
          course_id: item.id,
        })
      });

      examService.syncCourses(this.exam.id, this.exam_courses).then((response) => {
        this.resetForm()
        this.$snotify.success("Information updated");
      });

    },

    resetForm() {
      this.exam = {
        title: null,
        type: null,
        academic_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: null,
        exam_location: null,
        location_description: null,
        is_active: true
      }

      this.hideModal()

    }
  }
};
</script>
